<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondFeeding.vue
Description: This file is tab component of the feeding page which has functionality for scheduling at pond level. It has tables with row as ponds and columns as the parameters of the schedule.
-->
<template>
  <layout-pondlogs
    v-loading="loading"
    element-loading-background="white"
    headerType="card"
    :subHeader="false"
    containerType="card"
    class="pond-feeding"
  >
    <p slot="custom-title" class="layout-title">
      {{ $t("PM_pond_feeding") }}
    </p>
    <template slot="header">
      <p class="set-selection__label">{{ $t("PM_select_set") }}</p>
      &nbsp;
      <er-select
        :value="getSelectedFt"
        name="Please Select Templates"
        size="mini"
        value-key="_id"
        @change="handleChangeTemplate($event, 'CHANGE_EVENT')"
        :placeholder="selectInputPlaceHolderNames['templates']"
        popper-class="select-templates__drop-down"
        class="select-templates"
      >
        <el-option
          v-for="(template, id) in objFtIdFtDetails"
          :key="id"
          :label="template.title"
          :value="template"
        ></el-option>
      </er-select>
      &nbsp;
      <er-button
        :showLabel="false"
        :showIcon="true"
        class="edit-button"
        type="text"
        size="mini"
        btnType="edit"
        :disabled="isReadOnly"
        @click="handleEditTF(getSelectedFt)"
      ></er-button>
      <er-date-picker
        class="date-picker-schedule"
        :value="dateRange"
        type="daterange"
        size="mini"
        :clearable="false"
        :timeZoneString="getUserTimeZoneString"
        clear-icon=" "
        range-separator="-"
        start-placeholder="Start date"
        end-placeholder="End date"
        :format="this.upm__getFormatDateString"
        value-format="yyyy-MM-dd"
        :arrShortcuts="arrShortcuts"
        popper-class="schedule-date-picker-popper"
        :disableDateMethod="handleDisabledDate"
        @input="handleDateChange"
        :unlink-panels="true"
        :editable="false"
      ></er-date-picker>
    </template>
    <div slot="layout-pondlogs-scrollable-main">
      <div v-if="loading">
        <loader></loader>
      </div>
      <div shadow="never" v-else-if="doesScreenHasError !== ''">
        <center style="margin-bottom: 10px">
          <el-tag type="danger">{{ $t(doesScreenHasError) }}</el-tag>
        </center>
      </div>
      <div
        class="schedule-card"
        shadow="never"
        element-loading-background="white"
        v-else
      >
        <feed-entry-table></feed-entry-table>
        <el-row type="flex" class="schedule-card__footer">
          <el-alert
            v-if="getPondTitlesOtherThanSchedMode.length > 0"
            type="info"
            :closable="false"
          >
            <i class="el-icon-info"></i>
            {{
              `${$t(
                "Comn_ponds_with_other_modes"
              )} ${getPondTitlesOtherThanSchedMode.join(",")}`
            }}
          </el-alert>
          <er-button
            @click="submitScheduleDetails"
            btnType="save"
            size="mini"
            :showIcon="true"
            :showLabel="true"
            :disabled="isReadOnly"
          ></er-button>
        </el-row>
      </div>
      <feed-template-action-dialog
        :tabData="tabData"
        :dialogVisible="dialogVisible"
        :action="'EDIT'"
        :feedTemplate="feedTemplate"
        @closeDialog="handleCloseDialog"
      ></feed-template-action-dialog>
    </div>
  </layout-pondlogs>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import feedEntryTable from "@/components/schedules/feedEntryTable";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import Loader from "@/components/base/Loader";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import { FUTURE_SCHEDULES_DAY_LIMIT } from "@/constants/schedule";
import dateUtils from "@/utils/dateUtils";
import FeedTemplateActionDialog from "@/components/schedules/feedTemplateActionDialog.vue";
export default {
  props: ["tabData"],
  components: {
    feedEntryTable,
    Loader,
    FeedTemplateActionDialog
  },
  mixins: [errorHandlerMixin, datesHandlerMixin, userPreferenceMixin],
  data: function() {
    return {
      ObjSchedulesTimeslots: {
        schedules: {},
        timeSlots: []
      },
      pondsToActOn: undefined,
      selectedAction: "",
      dateRange: ["", ""],
      loading: false,
      actionLoading: false,
      location: "",
      pondsInScheduleMode: [],
      pondsInRunningSchedule: [],
      pondAlreadyHavingSchedules: [],
      feedTemplate: null,
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters("pondFeeding", {
      getSelectedFt: "getSelectedFt",
      getPondTFCache: "getPondTFCache",
      getFTIdToFTDetails: "getFTIdToFTDetails",
      getArrFeedTypes: "getArrFeedTypes",
      getPondTitlesOtherThanSchedMode: "getPondTitlesOtherThanSchedMode",
      getCurrDayExistSchedules: "getCurrDayExistSchedules",
      getPondIdToPondDetails: "getPondIdToPondDetails",
      getIsFTAvailable: "getIsFTAvailable",
      getPondsAcceptsSchedules: "getPondsAcceptsSchedules",
      noPondSelected: "noPondSelected",
      getPondIdsSelected: "getPondIdsSelected",
      getIsPondHasRunningScheds: "getIsPondHasRunningScheds",
      getCountOfValidTimeslotsForPond: "getCountOfValidTimeslotsForPond",
      groupPondIdToRunningSchedules: "groupPondIdToRunningSchedules",
        getFeedTypeChanged: "getFeedTypeChanged"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    existingFTs() {
      return this.$store.getters["schedules/getMapftIdFT"];
    },
    getLocationId() {
      return this.getCurrUserLocation._id;
    },
    objFtIdFtDetails: function() {
      return this.getFTIdToFTDetails;
    },
    getPondTitle: function() {
      return pondId => {
        return (this.getPondIdToPondDetails[pondId] || { title: "No Tile" })
          .title;
      };
    },
    getPondsLength: function() {
      return Object.values(this.getPondIdToPondDetails || {}).length;
    },
    getPondsAcceptsSchedulesLength: function() {
      return Object.keys(this.getPondsAcceptsSchedules || {}).length;
    },
    doesScreenHasError: function() {
      if (this.getPondsLength === 0) {
        return "Comn_culture_not_started_msg";
      }
      if (this.getPondsAcceptsSchedulesLength === 0) {
        return "PM_no_pond_sch_mode";
      }
      if (!this.getIsFTAvailable) {
        return "PM_no_sets_found";
      }
      return "";
    },
    mapPondIdSchedules() {
      return this.getMapPondIdSchedules;
    },
    getFeedTypes() {
      return this.getArrFeedTypes;
    },
    getSchedulesOfPonds() {
      return Object.values(this.ObjSchedulesTimeslots.schedules);
    },
    selectInputPlaceHolderNames() {
      return {
        ponds: this.$t("Ponds_select_ponds"),
        templates: this.$t("PM_select_set"),
        actions: this.$t("select_action")
      };
    },
    actions() {
      return {
        RUNNING: this.$t("PM_play"),
        PAUSED: this.$t("PM_pause"),
        STOPPED: this.$t("PM_stop")
      };
    },
    currentDate() {
      return dateUtils.getCurrDateInGivenTZ(this.getUserTimeZoneString);
    },

    arrShortcuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          -1,
          "days",
          this.$t("Comn_today"),
          this.currentDate,
          "TODAY"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          1,
          "days",
          this.$t("tomorrow"),
          this.currentDate,
          "FUTURE"
        ),

        this.dhm__dateUtilsLib.getDatePickerShortcut(
          2,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          3,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          4,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          5,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          6,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          14,
          "days",
          undefined,
          this.currentDate,
          "FUTURE"
        )
      ];
    }
  },

  async mounted() {
    await this.initDateRange();
    await this.initComponent();
  },
  methods: {
    ...mapActions("pondFeeding", {
      fetchAllPondDetails: "fetchAllPondDetails",
      fetchAllFeedTypes: "fetchAllFeedTypes",
      fetchAllFeedTemplates: "fetchAllFeedTemplates",
      changeDateRange: "changeDateRange",
      initFTTableData: "initFTTableData",
      changeSelectedFT: "changeSelectedFT",
      fetchAllPondMothers: "fetchAllPondMothers",
      savePondSchedules: "savePondSchedules",
      initPondIdToKGDT: "initPondIdToKGDT",
      fetchAllSchedDetails: "fetchAllSchedDetails",
      initUserTimeZoneString: "initUserTimeZoneString",
      changeStatusPondSched: "changeStatusPondSched",
      changeDateQueryType: "changeDateQueryType",
      changeTFAtSched: "changeTFAtSched"
    }),
    ...mapMutations("pondFeeding", {
      VALIDATE_TS_FOR_ALL_SELECTED_PONDS: "VALIDATE_TS_FOR_ALL_SELECTED_PONDS"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleEditTF(template) {
      this.feedTemplate = this.$lodash.cloneDeep(this.existingFTs[template._id]);
      this.dialogVisible = true;
    },
    async handleCloseDialog({ refetch }) {
      this.dialogVisible = false
      if (refetch) {
        await this.initComponent()
      }
    },
    handleDisabledDate(time) {
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.add(this.dhm__getTodayInUserTZ, {
            days: FUTURE_SCHEDULES_DAY_LIMIT
          }),
          this.getUserTimeZoneString
        )
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.subtract(this.dhm__getTodayInUserTZ, {
            days: 0
          }),
          this.getUserTimeZoneString
        )
      );
      const currTime = time;
      return !this.dhm__dateUtilsLib.queryInDateRange({
        dateRange: [startDate, endDate],
        date: currTime,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      });
    },
    getValidDateQueryType(date1, date2, format) {
      const today = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__getTodayInUserTZ
      );
      const date1ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date1)),
        this.getUserTimeZoneString
      );
      const date2ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date2)),
        this.getUserTimeZoneString
      );
      const day1 = this.dhm__dateUtilsLib.startOfDay(date1ISO);
      const day2 = this.dhm__dateUtilsLib.startOfDay(date2ISO);
      const isBefore = (a, b) => this.dhm__dateUtilsLib.isBefore(b, a);
      const isAfter = (a, b) => this.dhm__dateUtilsLib.isAfter(b, a);
      const isEqual = (a, b) => this.dhm__dateUtilsLib.isEqual(b, a);
      if (isBefore(today, day1) && isBefore(today, day2)) {
        return "PAST";
      } else if (isAfter(today, day1) && isAfter(today, day2)) {
        return "FUTURE";
      } else if (isEqual(today, day1) && isEqual(today, day2)) {
        return "TODAY";
      } else {
        throw {
          type: "FAIL_TO_SAVE",
          errors: [
            {
              message: this.$t("PM_dont_sel_current_date")
            }
          ]
        };
      }
    },
    handleClose() {
      this.ehm__unhandledErrorMessage = "";
    },
    async initDateRange() {
      this.loading = true;
      const initStartDate = this.dhm__dateUtilsLib.formatDate(
        this.dhm__getTodayInUserTZ,
        "yyyy-MM-dd"
      );
      const initEndDate = this.dhm__dateUtilsLib.formatDate(
        this.dhm__getTodayInUserTZ,
        "yyyy-MM-dd"
      );
      this.dateRange = [initStartDate, initEndDate];
      const dateRangeQueryType = "TODAY";
      this.changeDateQueryType(dateRangeQueryType);
      try {
        await this.changeDateRange(this.dateRange);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    initComponent: async function() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchAllPondMothers(),
          this.fetchAllPondDetails(),
          this.fetchAllFeedTemplates(),
          this.fetchAllFeedTypes()
        ]);
        await this.initUserTimeZoneString();
        await this.initPondIdToKGDT();
        // this.getFeedTemplates();
        if (this.getIsFTAvailable) {
          await this.handleChangeTemplate(
            Object.values(this.getFTIdToFTDetails)[0],
            "INIT_EVENT"
          );
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleChangeTemplate(value, event) {
      const changeHandler = async () => {
        await this.changeSelectedFT(value);
        await this.initFTTableData();
      };
      if (event === "INIT_EVENT") {
        await changeHandler();
        return;
      }
      if (event === "CHANGE_EVENT") {
        this.mixPanelEventGenerator({ eventName: "Feeding - Pond Feeding - Set Dropdown" });
      }
      this.loading = true;
      setTimeout(async () => {
        await changeHandler();
        await this.updateTFFromCacheInPonds();
        this.$nextTick(() => {
          this.loading = false;
        });
      }, 500);
    },
    async updateTFFromCacheInPonds() {
      const pondIDs = Object.keys(this.getPondTFCache);
      if (pondIDs.length) {
        const feedPromises = pondIDs.map(pondId => {
          return this.changeTFAtSched({
            pondId,
            updtVal: this.getPondTFCache[pondId]
          });
        });
        await Promise.all(feedPromises);
      }
    },
    async handleDateChange(value) {
      try {
        this.loading = true;
        const dateRangeQueryType = this.getValidDateQueryType(
          ...value,
          "YYYY-MM-DD"
        );
        this.dateRange = value;
        this.changeDateQueryType(dateRangeQueryType);
        await this.changeDateRange(value);
        await this.initFTTableData();
        this.mixPanelEventGenerator({ eventName: "Feeding - Pond Feeding - Date Filter" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleActionOnPonds(value) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.actionLoading = true;
        await this.changeStatusPondSched({
          sched: this.pondsToActOn,
          status: this.selectedAction
        });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("PM_sch_updated_success"),
          duration: 5000,
          type: "success"
        });
        this.pondsToActOn = undefined;
        this.selectedAction = "";
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.actionLoading = false;
      }
    },
    async checkWhetherToSaveTheSchedules() {
      await this.$confirm(
        this.$t("Comn_sch_upd_all_future_scheds"),
        this.$t("Comn_schedules"),
        {
          confirmButtonText: this.$t("Comn_save"),
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
    },
    async submitScheduleDetails() {
      // let payloadSchedules = []\
      this.fetchAllPondDetails();
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (this.noPondSelected) {
          throw {
            type: "store",
            errors: [{ message: this.$t("Comn_select_atleast_pond") }]
          };
        }
        const errors = [];
        this.getPondIdsSelected.forEach(pondId => {
          const hasRunningSchedule = this.getIsPondHasRunningScheds(pondId);
          if (!hasRunningSchedule) return;
          const countOfValidSchedules = this.getCountOfValidTimeslotsForPond(
            pondId
          );
          if (countOfValidSchedules === 0) {
            errors.push({
              type: "schedule_min_count",
              pond_title: this.getPondTitle(pondId)
            });
          }
          if (countOfValidSchedules > 7) {
            errors.push({
              type: "schedule_max_count",
              pond_title: this.getPondTitle(pondId)
            });
          }
        });
        if (errors.length > 0) {
          throw {
            type: "FAIL_SAVE",
            errors
          };
        }
        this.VALIDATE_TS_FOR_ALL_SELECTED_PONDS({
          groupPondIdToRunningSchedules: this.groupPondIdToRunningSchedules
        });
        await this.checkWhetherToSaveTheSchedules();
        this.loading = true;
        await this.savePondSchedules(); // api calls
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("PM_sch_updated_success"),
          duration: 5000,
          type: "success"
        });
        const changedData = this.getFeedTypeChanged
        if (changedData.includes(true)) {
          this.mixPanelEventGenerator({ eventName: "Feeding - Pond Feeding - Feed Type Dropdown" });
        }
        this.mixPanelEventGenerator({ eventName: "Feeding - Pond Feeding - Save" });
      } catch (err) {
        if (err && err.type === "store") {
          this.ehm__errorFailToSave(err.errors);
        } else if (err && err.type === "FAIL_SAVE") {
          const errorArrString = err.errors.map(errInSched => {
            const inPondString = `${this.$t("Comn_in")} ${
              errInSched.pond_title
            }`;
            switch (errInSched.type) {
              case "timeslot_min_feed":
                return `${this.$tc(errInSched.message, 1, {
                  field: this.$t("Comn_total_feed"),
                  threshold: (+errInSched.threshold.toFixed(2)).toString(),
                })} ${this.$t(
                  "Comn_in"
                )} ${this.$t("Comn_schedule")} ${
                  errInSched.time_slot_id
                } ${this.$t("of")} ${errInSched.pond_title}`
              case "timeslot_feed":
                return `${this.$t(errInSched.message)} ${this.$t(
                  "Comn_in"
                )} ${this.$t("Comn_schedule")} ${
                  errInSched.time_slot_id
                } ${this.$t("of")} ${errInSched.pond_title}`;
              case "schedule_min_count":
                return this.$t("no_timeslots_availble_for_pond", {
                  pond: errInSched.pond_title
                });
              case "schedule_max_count":
                return this.$t("pond_max_8_schedule_count", {
                  pond: errInSched.pond_title
                });
              case "feed":
                return `${this.$t(errInSched.message, {
                  threshold: errInSched.threshold
                })} ${inPondString}`;
              default:
                return `${this.$t(errInSched.message)} ${inPondString}`;
            }
          });
          const errors = errorArrString.map(x => ({ message: x }));
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler: function(err) {
      // let errors = [];
      // if (err.response.data.errors != null) {
      //   err.response.data.errors.details.forEach((error, index) => {
      //     if (error.errors != null) {
      //       error.errors.forEach((nestedErr, index) => {
      //         errors.push({ message: nestedErr.message });
      //       });
      //     } else {
      //       errors.push({ message: error.message });
      //     }
      //   });
      // } else {
      //   errors = [{ message: err.response.data.message }];
      // }
      let errors = [];
      const errorCodes = ['OVERLAP_SCHEDULES_AND_CHANGED_FROM_OTHER_SOURCE', 'SCHEDULES_ALREADY_EXISTS_FOR_GIVEN_RANGE'];
      if (err.response.data.error_code != null && errorCodes.includes(err.response.data.error_code)) {
          errors.push({ message: this.$t('Overlapped_error_message') });
      } else {
        errors = [{ message: err.response.data.message }]
      }
      this.ehm__errorFailToSave(errors);
    }
  }
};
</script>

<style lang="scss">
$font-color: white;
.pond-feeding-error-lists {
  padding-left: 15px;
  max-height: 300px;
  overflow-y: auto;
}
.select__set {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
  display: flex;
  align-items: center;
  justify-content: center;

  .select-templates__drop-down {
    .el-select-dropdown__item span {
      width: 11em;
      display: block;
      position: relative;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.is-multiple .el-select-dropdown__item.selected::after {
      top: 0px;
    }
  }
}
.schedule-date-picker-popper {
  .el-picker-panel__sidebar {
    left: 0;
  }
  .el-picker-panel__body {
    margin-right: 0px;
    margin-left: 0;
  }
}
.selected {
    background-color: #F5F7FA !important;
  }
.pond-feeding {
  &.pondlogs-default-layout {
    overflow-x: hidden;
    padding: 0px;
    .card-layout .el-card__body {
      padding: unset;
      // background-color: #66b3ff;
      // color: $font-color;
    }
  }
  .pond-actions__label {
    @include responsiveProperty(font-size, 1rem, 1rem, 0.8rem);
    text-align: left;
  }
  .set-selection__label {
    text-align: right;
  }
  .date-picker-schedule {
    // @include responsiveProperty(flex-basis, 25%, 20%, 15%);
  }
  .selectors-items--left,
  .selectors-items--right {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  }
  .selectors-items--right {
    line-height: 1;
    div[class^="selectors-items-"] {
      line-height: 2.3;
    }
  }
  .toolbar-layout {
    .select-templates {
      width: 180px;
      .el-input {
        @include small-text;
        .el-input__inner {
          text-transform: capitalize;
        }
      }
    }
  }
  .controls--button {
    margin: 10px;
    text-align: center;
  }
  .selectors-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .schedule-card__footer {
    margin: 5px 5px;
    .er-button {
      margin-left: auto;
    }
    .el-alert {
      width: unset;
      display: inline-flex;
      padding: 5px 10px;
      .el-alert__description {
        margin: unset;
      }
    }
  }
  .er-button.er-button--icon span.material-icons-outlined {
    font-size: 18px;
  }
  .edit-button.er-button.el-button--mini {
    padding: 5px 0px;
    margin-right: 16px;
    margin-left: 0px;
  }
}
</style>
