/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pmSchedulesMixin.js
Description: This mixin contains the common functionality related to the pondmother schedules in feeding page
*/
import { mapActions, mapGetters } from "vuex";
import {
  POND_TS_STATUS,
  PM_TS_STATUS,
  MAX_OCF_VALUE,
  MAX_E_TIME_SECS_ALLOWED,
  TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
  FUTURE_SCHEDULES_DAY_LIMIT
} from "@/constants/schedule";
import tableEmptyState from "@/components/pmSchedules/tableEmptyState";
import communication from "@/components/pmSchedules/baseComponents/communication";
import scheduleStatus from "@/components/pmSchedules/baseComponents/scheduleStatus";
import refresh from "@/components/pmSchedules/baseComponents/refresh";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import filtersMixin from "@/mixins/filtersMixin";
import { castSecsHHmmStr } from "../utils/commonUtils";
const tsCssClassNames = {
  default: "card-row-table__row",
  SCHED_MISMATCHED: "ts--mismatched",
  SCHED_COMPLETED: "ts--completed"
};
const pmSchedulesMixin = {
  mixins: [datesHandlerMixin, userPreferenceMixin, filtersMixin],
  data: function() {
    return {
      tsCssClassNames
    };
  },
  components: {
    tableEmptyState,
    communication,
    scheduleStatus,
    refresh
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getArrOfDaysFromDateRange: "getArrOfDaysFromDateRange",
      getExpandedPmKeysPondId: "getExpandedPmKeysPondId",
      getPmTSTableDate: "getPmTSTableData",
      getSelectedPondPmIds: "getSelectedPondPmIds",
      getSelectedPmIds: "getSelectedPmIds",
      getAllPondsPmDetailsToPmId: "getAllPondsPmDetailsToPmId",
      getDateQueryType: "getDateQueryType",
      isCurrentDay: "isCurrentDay",
      permissions: "getPermissions",
      getSelectedPondPmDetailsToPmId: "getSelectedPondPmDetailsToPmId",
      getSelectedPond: "getSelectedPond",
      getPondDetailsToPondId: "getPondDetailsToPondId",
      getExistSchedDtSecsToPondIdToPondSchedDtls:
        "getExistSchedDtSecsToPondIdToPondSchedDtls"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    getRunningCultureForSelectedPond() {
      const pond = this.getSelectedPond;
      let currentCulture = null;
      if (pond && pond.cultivation_date && pond.cultivation_date !== "") {
        currentCulture = {
          start_date: pond.cultivation_date,
          post_larva_stocked: pond.post_larva_stocked,
          end_date: this.dhm__dateUtilsLib
            .zonedTimeToUtc(this.dhm__getTodayInUserTZ, "UTC")
            .toISOString(),
          harvest_id: 0,
          name: this.$t("Comn_current_culture")
        };
        currentCulture.doc = this.dhm__dateUtilsLib.differenceInDays(
          this.dhm__dateUtilsLib.parseISO(currentCulture.end_date),
          this.dhm__dateUtilsLib.parseISO(currentCulture.start_date)
        );
        return currentCulture;
      }
      return {};
    },
    tableColumns: function() {
      return {
        s_time: {
          label: this.$t("Comn_from_time"),
          width: 100
        },
        e_time: {
          label: this.$t("Comn_to_time"),
          width: 100
        },
        total_feed_kgs: {
          label: "TF (kg)",
          width: 80
        },
        ocf_g: {
          label: "OCF (g)",
          width: 80
        },
        dispensed_feed: {
          label: "DF (kg)",
          width: 80
        },
        feed_gap: {
          label: "FG (min)",
          width: 80
        },
        actions: {
          label: this.$t("Comn_actions"),
          width: 150
        },
        mode_g: {
          label: this.$t("mode"),
          width: 150
        },
        feeding_level_g: {
          label: this.$t("Comn_feed_level"),
          width: 135
        }
      };
    },
    isPmIsInPond() {
      return pmId => {
        const pmIdsInPond = this.getSelectedPondPmIds || [];
        if (pmIdsInPond.includes(pmId)) {
          return true;
        }
        return false;
      };
    },
    pmsLength: function() {
      return this.getSelectedPmIds.length;
    },
    getSelectedPmsObjWithDetails() {
      return this.getSelectedPmIds
        .map(pmId => this.getSelectedPondPmDetailsToPmId[pmId])
        .reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {});
    },
    hidePMComponentActionBtns() {
      return pmId => {
        const conditions = [
          !this.isPmIsInPond(pmId),
          this.hideClearFutureSchedules
        ];
        return conditions.some(x => x);
      };
    },
    hideClearFutureSchedules() {
      return this.getDateQueryType === "PAST";
    },
    hideAddSaveBtnsAtPondLevel() {
      return this.getDateQueryType === "PAST";
    },
    currentDayValue: function() {
      return this.dhm__dateUtilsLib
        .startOfDay(this.dhm__getTodayInUserTZ)
        .getTime();
    },
    getErrorMessage() {
      return (errorObject, prop) => {
        return Number.isInteger(errorObject[prop])
          ? `${this.$t("PM_sch_overlapping_pls_check")} ${errorObject[prop]}`
          : this.$t(errorObject[prop]);
      };
    },
    hideStatusActionBtns: function() {
      return ts => {
        return this.isPmTSCompleted(ts);
      };
    },
    hidePondStatusActionBtns: function() {
      return ts => {
        const pmkeys = Object.keys(ts.pmsSubscribe);
        if (pmkeys.length < this.pmsLength) {
          return true;
        }
        const pmStatus = pmkeys.map(pmId => {
          const key = `${this.selectedDay}_${ts.pond_id}_${pmId}`;
          return this.getPmTSTableDate[key][ts.pmsSubscribe[pmId]].status;
        });
        const pondStatus = [...new Set(pmStatus)];
        if (pondStatus.length > 1) {
          return true;
        }
        return this.isPondTSCompleted(ts);
      };
    },
    hidePondDivider: function() {
      return ts => {
        const pmkeys = Object.keys(ts.pmsSubscribe);
        if (pmkeys.length < this.pmsLength) {
          return true;
        }
        return (
          [POND_TS_STATUS.RUNNING, POND_TS_STATUS.PAUSED].indexOf(
            ts.ui_status
          ) === -1
        );
      };
    },
    popperClass() {
      return `pm-scheds-error-message-popper ${!this.isExpanded ? "hide" : ""}`;
    }
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      changeStatusToSaveForMultipleDays: "changeStatusToSaveForMultipleDays",
      deleteFutureTSAndSchedules: "deleteFutureTSAndSchedules"
    }),
    ...mapActions("pondMotherSchedules/scheduleActions", {
      changeStatusOfPondTS: "changeStatusOfPondTS",
      changeStatusOfPmTS: "changeStatusOfPmTS",
      changeStatusOfPmTSAtUI: "changeStatusOfPmTSAtUI",
      changeStatusOfPondTSAtUI: "changeStatusOfPondTSAtUI"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    doPondTSHasSelectedPms(pondTS) {
      const pondTSPmIds = new Set(Object.keys(pondTS.pmsSubscribe));
      return this.getSelectedPmIds.every(x => pondTSPmIds.has(x));
    },
    isPmTSCompleted(pmTS) {
      return (
        [
          PM_TS_STATUS.COMPLETED,
          PM_TS_STATUS.UI_COMPLETED,
          PM_TS_STATUS.STOPPED
        ].indexOf(pmTS.ui_status) > -1
      );
    },
    isPondTSCompleted(pondTS) {
      return (
        [
          POND_TS_STATUS.COMPLETED,
          POND_TS_STATUS.UI_COMPLETED,
          POND_TS_STATUS.STOPPED
        ].indexOf(pondTS.ui_status) > -1
      );
    },
    async handleFailSaveErrors(errors) {
      const fieldLangKeys = {
        feed: this.$t("Comn_total_feed"),
        feed_gap: this.$t("Comn_feed_gap"),
        ocf: this.$t("Comn_ocf")
      };
      const errorMessages = [];
      const objIdsToIndex = this.tableData.reduce((acc, curr, index) => {
        acc[curr.ui_id] = index;
        return acc;
      }, {});
      errors.forEach(errorObj => {
        const scheduleNo = objIdsToIndex[errorObj.schedule_id];
        const error = errorObj.error;
        let inScheduleString = `${this.$t("Comn_in")} ${this.$t(
          "Comn_schedule"
        )} ${scheduleNo + 1}`;
        inScheduleString +=
          error.validation_flow === "pond_to_pm"
            ? ` ${this.$t("for_item_with_id", {
                item: "PondMother",
                code: error.pm_title
              })}`
            : "";
        let message = "";
        switch (error.type) {
          case "feed":
            switch (error.validation) {
              case "empty":
                message = `${this.$t("Comn_must_not_be_empty", {
                  field: fieldLangKeys[error.type]
                })} ${inScheduleString}`;
                break;
              case "minimum":
                message = `${this.$tc("Comn_must_greater_than_or_equal", 1, {
                  field: fieldLangKeys[error.type],
                  threshold: (+error.threshold.toFixed(2)).toString()
                })} ${inScheduleString} `;
                break;
              case "maximum":
                message = `${this.$t("Comn_must_less_than_threshold", {
                  field: fieldLangKeys[error.type],
                  threshold: (+error.threshold.toFixed(2)).toString()
                })} ${inScheduleString}`;
                break;
              case "less_than_dispensed_feed":
                message = `${this.$tc("Comn_must_greater_than", 1, {
                  field: fieldLangKeys[error.type],
                  other_field: this.$t("PM_dispensed_feed")
                })} ${inScheduleString}`;
                break;
            }
            break;
          case "ocf":
            switch (error.validation) {
              case "empty":
                message = `${this.$t("Comn_must_not_be_empty", {
                  field: fieldLangKeys[error.type]
                })} ${inScheduleString}`;
                break;
              case "minimum":
                if (error.threshold === MAX_OCF_VALUE) {
                  message = `${this.$t("Comn_must_be_threshold", {
                    field: fieldLangKeys[error.type],
                    threshold: error.threshold
                  })} ${inScheduleString} `;
                } else {
                  message = `${this.$t("Comn_must_lie_between_thresholds", {
                    field: fieldLangKeys[error.type],
                    threshold1: error.threshold,
                    threshold2: MAX_OCF_VALUE
                  })} ${inScheduleString} `;
                }
                break;
              case "maximum":
                message = `${this.$t("Comn_must_less_than_threshold", {
                  field: fieldLangKeys[error.type],
                  threshold: error.threshold
                })} ${inScheduleString}`;
                break;
            }
            break;
          case "feed_gap":
            message = `${this.$t("invalid_schedule_feed", {
              index: scheduleNo + 1
            })}`;
            break;
          case "modeChack":
            message = `${this.$t("Comn_field_is_required", {
              field: error.fieldName
            })}`;
            break;
          case "stCheckForHybridMode":
            if (errorObj?.pmData) {
              message = this.$t("item_not_yet_assigned_to_source", {
                item: errorObj?.pmData.code,
                source: this.$t("Comn_shrimptalk")
              });
            } else {
              message = this.$t("item_not_yet_assigned", {
                item: this.$t("Comn_shrimptalk")
              });
            }
            break;
          case "time":
            switch (error.validation) {
              case "start_time_minimum":
                message = `${this.$t("PM_start_time_not_less_than_0015", {
                  position: scheduleNo + 1
                })}`;
                break;
              case "end_time_maximum":
                message = `${this.$t("PM_end_time_less_than_end_time", {
                  position: scheduleNo + 1,
                  end_time: castSecsHHmmStr(MAX_E_TIME_SECS_ALLOWED)
                })}`;
                break;
              case "end_time_greater_current_time":
                message = `${this.$t(
                  "PM_current_time_less_than_stop_time"
                )} ${inScheduleString}`;
                break;
              case "end_time_greater_start_time":
                message = `${this.$t(
                  "PM_start_time_less_than_stop_time"
                )} ${inScheduleString}`;
                break;
              case "start_time_running_greater_end_time":
                message = `${this.$t(
                  "PM_running_time_greater_than_stop_time"
                )} ${inScheduleString}`;
                break;
              case "start_time_less_from_time":
                message = `${this.$t(
                  "PM_frm_time_more_than_5_mins_msg"
                )} ${inScheduleString}`;
                break;
              case "end_time_before_5min_completion":
                message = `${this.$t(
                  "PM_5min_before_chng_msg"
                )} ${inScheduleString}`;
                break;
              case "overlap":
                message = this.$t("PM_sch_is_overlapping_msg", {
                  position1: scheduleNo + 1,
                  position2: objIdsToIndex[error.schedule_id] + 1
                });
                break;
              case "possible_to_give_timeslots":
                message = `${this.$t("schedule_cannot_created")}`;
                break;
              default:
                message = `${this.$t(error.message)} ${inScheduleString}`;
            }
            break;
        }
        errorMessages.push({ message });
      });

      // const h = this.$createElement;
      try {
        await this.ehm__errorFailToSave(errorMessages);
      } catch (err) {
        console.log(err);
      }
    },
    async errorFailToSaveHandler(err) {
      if (err.type === "store") {
        let message = err.message;
        if (err.message_type === "lang_key") {
          message = this.$t(err.message);
        }
        this.ehm__errorFailToSave([{ message }]);
      } else if (err.type === "FAIL_SAVE") {
        if (err.validation_type === "NO_FIELD_CHANGED") {
          const message = this.$t("Comn_no_changes_found");
          this.ehm__errorFailToSave([{ message }]);
        } else {
          await this.handleFailSaveErrors(err.errors);
        }
      } else {
        this.ehm__errorMessages(err, true);
      }
    },
    async handlePMClearFutureSchedules(event, pmId) {
      try {
        this.$gblUAMCanUserDelete(this.permissions);
        this.loading = pmId;
        await this.deleteConfirm();
        await this.deleteFutureTSAndSchedules({ pmId, pondId: this.pondId });
        this.notifyScheduleSuccess("delete");
        this.mixPanelEventGenerator({
          eventName:
            "Feeding - PondMother - PondMother Collapse - Clear future Schedules"
        });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = "";
      }
    },
    async handlePondClearFutureSchedules(event, pondId) {
      try {
        this.$gblUAMCanUserDelete(this.permissions);
        this.loading = pondId;
        await this.deleteConfirm();
        await this.deleteFutureTSAndSchedules({ pondId });
        this.notifyScheduleSuccess("delete");
        // this.currentActivePond = "";
        this.mixPanelEventGenerator({
          eventName:
            "Feeding - PondMother - Pond Collapse - Clear future Schedules"
        });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = "";
      }
    },
    handleFocusOnPmTS(component, pmTS, inputType) {
      if (!this.isCurrentDay) return;
      if (!pmTS.bk_id) return;
      const dateObj = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
      const hours = this.dhm__dateUtilsLib.getHours(dateObj);
      const mins = this.dhm__dateUtilsLib.getMinutes(dateObj);
      const prsntTimeSecs = hours * 3600 + mins * 60;
      const bkStimeInSecs = pmTS.bk_s_time_secs;
      const bkEtimeInSecs = pmTS.bk_e_time_secs;
      const isBkSTimeLessThanCurrTime =
        bkStimeInSecs - prsntTimeSecs < TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS;
      const isBkETimeLessThanCurrTime =
        bkEtimeInSecs - prsntTimeSecs < TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS;
      const isTSIsTOBERUN = pmTS.ui_status === PM_TS_STATUS.TO_BE_RUN;
      const isTSIsRunning = [
        PM_TS_STATUS.RUNNING,
        PM_TS_STATUS.UI_RUNNING,
        PM_TS_STATUS.PAUSED
      ].includes(pmTS.ui_status);
      const conditionToSatisfy = [
        isBkSTimeLessThanCurrTime && isTSIsTOBERUN,
        isBkETimeLessThanCurrTime && isTSIsRunning
      ];
      if (conditionToSatisfy.every(x => !x)) return;
      this.changeStatusOfPmTSAtUI({
        selectedDay: +this.selectedDay,
        currPmTSId: pmTS.ui_id,
        pondId: this.pondId,
        pmId: this.pmId,
        status: PM_TS_STATUS.UI_RUNNING
      });
      this.ehm__errorFailToSave([
        {
          message: this.$t(
            "timeslot-is-disabled-because-data-is-not-sync-with-device-need-to-refresh-to-page"
          )
        }
      ]);
      if (inputType !== "TIME_PICKER") return;
      this.$nextTick(() => {
        component.hidePicker();
      });
    },
    handleFocusOnPondTS(component, pondTS, inputType) {
      if (!this.isCurrentDay) return;
      if (!pondTS.bk_id) return;
      const dateObj = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
      const hours = this.dhm__dateUtilsLib.getHours(dateObj);
      const mins = this.dhm__dateUtilsLib.getMinutes(dateObj);
      const prsntTimeSecs = hours * 3600 + mins * 60;
      const bkStimeInSecs = pondTS.bk_s_time_secs;
      const bkEtimeInSecs = pondTS.bk_e_time_secs;
      const isBkSTimeLessThanCurrTime =
        bkStimeInSecs - prsntTimeSecs <= TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS;
      const isBkETimeLessThanCurrTime =
        bkEtimeInSecs - prsntTimeSecs <= TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS;
      const isTSIsTOBERUN = pondTS.ui_status === POND_TS_STATUS.TO_BE_RUN;
      const isTSIsRunning = [
        POND_TS_STATUS.RUNNING,
        POND_TS_STATUS.UI_RUNNING,
        POND_TS_STATUS.PAUSED
      ].includes(pondTS.ui_status);
      const conditionToSatisfy = [
        isBkSTimeLessThanCurrTime && isTSIsTOBERUN,
        isBkETimeLessThanCurrTime && isTSIsRunning
      ];
      if (conditionToSatisfy.every(x => !x)) return;
      this.changeStatusOfPondTSAtUI({
        selectedDay: +this.selectedDay,
        currPondTSId: pondTS.ui_id,
        pondId: this.pondId
      });
      this.ehm__errorFailToSave([
        {
          message: this.$t("need-to-refresh-to-page")
        }
      ]);
      if (inputType !== "TIME_PICKER") return;
      this.$nextTick(() => {
        component.hidePicker();
      });
    },
    functionPmTSTableRowClassName({ row, rowIndex }) {
      const rowClass = [this.tsCssClassNames.default];
      if (row.status === PM_TS_STATUS.COMPLETED) {
        rowClass.push(this.tsCssClassNames.SCHED_COMPLETED);
      }
      return rowClass.join(" ");
    },
    functionPondTSTableRowClassName({ row, rowIndex }) {
      const pmkeys = Object.keys(row.pmsSubscribe);
      const rowClass = [this.tsCssClassNames.default];
      if (row.status === POND_TS_STATUS.COMPLETED) {
        rowClass.push(this.tsCssClassNames.SCHED_COMPLETED);
      } else if (pmkeys.length < this.pmsLength) {
        rowClass.push(this.tsCssClassNames.SCHED_MISMATCHED);
      }
      return rowClass.join(" ");
    },
    checkEndTimeLessThanCurrentTime(ts) {
      const prsnt_time_secs = this.dhm__dateUtilsLib.getCurrTimeSecsInGivenTZ(
        this.getUserTimeZoneString
      );
      const currDaySecs = this.currentDayValue / 1000;
      const presTSDaySecs = +this.selectedDay / 1000;
      if (ts.bk_id) {
        return prsnt_time_secs + currDaySecs > ts.e_time_secs + presTSDaySecs;
      } else {
        return false;
      }
    },
    checkRange1LiesBtwRange2(range1, range2) {
      const s1h1 = range1[0];
      const s1h2 = range1[1];
      const s2h1 = range2[0];
      const s2h2 = range2[1];
      if (s2h1 <= s1h1 && s2h2 >= s1h2) {
        return true;
      }
      return false;
    },
    checkCurrentTimeLiesInGivenTimeRange(tsStimeSecs, tsEtimeSecs) {
      const dateObj = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
      const hours = this.dhm__dateUtilsLib.getHours(dateObj);
      const mins = this.dhm__dateUtilsLib.getMinutes(dateObj);
      const prsntTimeSecs = hours * 3600 + mins * 60;
      if (prsntTimeSecs > tsStimeSecs && prsntTimeSecs < tsEtimeSecs) {
        return true;
      }
      return false;
    },
    checkSTimeLessThanCurrentTime(ts) {
      const dateObj = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
      const hours = this.dhm__dateUtilsLib.getHours(dateObj);
      const mins = this.dhm__dateUtilsLib.getMinutes(dateObj);
      const prsnt_time_secs = hours * 3600 + mins * 60;
      const currDaySecs = this.currentDayValue / 1000;
      const presTSDaySecs = +this.selectedDay / 1000;
      if (ts.bk_id) {
        return prsnt_time_secs + currDaySecs > ts.s_time_secs + presTSDaySecs;
      } else {
        return false;
      }
    },
    deleteConfirm: function() {
      return this.$confirm(
        this.$t("delete_all_future_scheds_confirm_msg"),
        this.$t("Comn_delete"),
        {
          confirmButtonText: this.$t("Comn_delete"),
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
    },
    TSStatusChangeConfirm: function(status) {
      const statusToConfirmMessage = {
        [PM_TS_STATUS.PAUSED]: {
          message: this.$t("schedule_status_change_confirm_box.message.pause"),
          title: this.$t("schedule_status_change_confirm_box.title", {
            status: this.$t("PM_pause")
          }),
          buttonText: this.$t("Comn_confirm"),
          box_type: "warning",
          confirmBtnClass: "er-button er-button--warning"
        },
        [PM_TS_STATUS.STOPPED]: {
          message: this.$t("schedule_status_change_confirm_box.message.stop"),
          title: this.$t("schedule_status_change_confirm_box.title", {
            status: this.$t("PM_stop")
          }),
          buttonText: this.$t("Comn_confirm"),
          box_type: "error",
          confirmBtnClass: "er-button er-button--confirm"
        },
        [PM_TS_STATUS.RUNNING]: {
          message: this.$t(
            "schedule_status_change_confirm_box.message.running"
          ),
          title: this.$t("schedule_status_change_confirm_box.title", {
            status: this.$t("PM_running")
          }),
          buttonText: this.$t("Comn_confirm"),
          box_type: "warning",
          confirmBtnClass: "er-button er-button--warning"
        }
      };
      return this.$confirm(
        statusToConfirmMessage[status].message,
        statusToConfirmMessage[status].title,
        {
          confirmButtonText: statusToConfirmMessage[status].buttonText,
          cancelButtonText: this.$t("Comn_cancel"),
          type: statusToConfirmMessage[status].box_type,
          confirmButtonClass: statusToConfirmMessage[status].confirmBtnClass
        }
      );
    },
    notifyScheduleSuccess: function(action) {
      const actionToMessage = {
        saved: "PM_sch_updated_success",
        update: "PM_sch_updated_success",
        delete: "PM_future_sch_deleted_success",
        status: "PM_status_updated_successfully"
      };
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message: this.$t(actionToMessage[action]),
        type: "success"
      });
    },
    notifyScheduleFailed: function(action) {
      const actionToMessage = {
        saved: "PM_no_upd_fail_device_conn",
        update: "PM_no_upd_fail_device_conn",
        delete: "PM_no_upd_fail_device_conn",
        status: "PM_no_upd_fail_device_conn"
      };
      this.$notify({
        title: this.$t("failed"),
        message: this.$t(actionToMessage[action]),
        type: "error"
      });
    },
    checkWhetherToSaveSameSchedForRemainingDays: async function() {
      const count = this.getArrOfDaysFromDateRange.length;
      if (count > 1) {
        return this.$confirm(
          this.$t("Comn_sch_upd_all_dates"),
          this.$t("warning"),
          {
            confirmButtonText: this.$t("Comn_save"),
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        )
          .then(async () => {
            await this.changeStatusToSaveForMultipleDays(true);
          })
          .catch(async () => {
            this.changeStatusToSaveForMultipleDays(false);
          });
      } else {
        this.changeStatusToSaveForMultipleDays(false);
      }
    },

    disableDateMethod: function(time) {
      if (!this.getRunningCultureForSelectedPond.doc) {
        return false;
      }
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.add(this.dhm__getTodayInUserTZ, {
            days: FUTURE_SCHEDULES_DAY_LIMIT
          }),
          this.getUserTimeZoneString
        )
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.subtract(endDate, {
          days:
            this.getRunningCultureForSelectedPond.doc +
            FUTURE_SCHEDULES_DAY_LIMIT
        }),
        this.getUserTimeZoneString
      );
      const currTime = time;

      return !this.dhm__dateUtilsLib.queryInDateRange({
        dateRange: [startDate, endDate],
        date: currTime,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      });
    },
    changeDateMethod: function(time) {
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.add(this.dhm__getTodayInUserTZ, {
            days: 0
          }),
          this.getUserTimeZoneString
        )
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.subtract(endDate, {
          days: 0
        }),
        this.getUserTimeZoneString
      );

      const currTime = time;
      return !this.dhm__dateUtilsLib.queryInDateRange({
        dateRange: [startDate, endDate],
        date: currTime,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      });
    },

    getValidDateQueryType(date1, date2, format, throwError = true) {
      const tempDate1 = this.dhm__dateUtilsLib.parse(date1, format, new Date());
      const tempDate2 = this.dhm__dateUtilsLib.parse(date2, format, new Date());
      if (
        [
          [tempDate1, tempDate2].some(x => !this.dhm__dateUtilsLib.isValid(x)),
          this.dhm__dateUtilsLib.isAfter(tempDate1, tempDate2)
        ].some(x => x)
      ) {
        return "INVALID_RANGE";
      }
      const date1ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date1)),
        this.getUserTimeZoneString
      );
      const date2ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date2)),
        this.getUserTimeZoneString
      );

      const today = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__getTodayInUserTZ
      );

      const day1 = this.dhm__dateUtilsLib.startOfDay(date1ISO);
      const day2 = this.dhm__dateUtilsLib.startOfDay(date2ISO);
      const isBefore = (a, b) => this.dhm__dateUtilsLib.isBefore(b, a);
      const isAfter = (a, b) => this.dhm__dateUtilsLib.isAfter(b, a);
      const isEqual = (a, b) => this.dhm__dateUtilsLib.isEqual(b, a);

      if (isBefore(today, day1) && isBefore(today, day2)) {
        return "PAST";
      } else if (isAfter(today, day1) && isAfter(today, day2)) {
        return "FUTURE";
      } else if (isEqual(today, day1) && isEqual(today, day2)) {
        return "TODAY";
      } else {
        if (throwError) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("PM_dont_sel_current_date")
              }
            ]
          };
        }
        return "INVALID_RANGE";
      }
    }
  }
};

export default pmSchedulesMixin;
